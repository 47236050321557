<!-- 质检记录页面 -->
<template>
  <div class="testRecord-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="detailTest-detail">
      <img
        v-if="shopDetail.shopLogo"
        class="detail-img"
        :src="shopDetail.shopLogo"
        alt="icon"
      />
      <span class="user-icon" v-else
        ><i class="el-icon-user iconPeople"></i
      ></span>
      <div>
        <div class="detail-title">
          店铺名称: {{ shopDetail.shopName || "--" }}
        </div>
        <span class="detail-item"
          >所属公司: {{ shopDetail.companyName || "--" }}</span
        >
        <span class="detail-item"
          >所属平台: {{ shopDetail.platformName || "--" }}</span
        >
        <span class="detail-item"
          >所属类目: {{ shopDetail.categoryName || "--" }}</span
        >
        <span class="detail-item"
          >所属客户: {{ shopDetail.customerName || "--" }}</span
        >
      </div>
    </div>
    <!-- 搜索框区域 -->
    <div class="common-screen-container">
      <!-- 第一行 -->
      <!-- <el-row>
        <el-col :span="6"> -->
      <div class="common-input-container">
        <span>问题大类:</span>
        <el-select
          v-model="params.classificationQualityBigId"
          class="common-screen-input"
          placeholder="请选择"
          clearable
          filterable
          @change="(value) => dictFun(value, 'quality', 'quality2')"
        >
          <el-option
            v-for="item in quality1"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- </el-col>
        <el-col :span="6"> -->
      <div class="common-input-container">
        <span>问题点:</span>
        <el-select
          v-model="params.classificationQualityPointId"
          class="common-screen-input"
          placeholder="请选择"
          clearable
          filterable
          @change="(value) => dictFun(value, 'quality', 'quality3')"
        >
          <el-option
            v-for="item in quality2"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- </el-col>
        <el-col :span="6"> -->
      <div class="common-input-container">
        <span>问题描述:</span>
        <el-select
          v-model="params.classificationQualityDescId"
          class="common-screen-input"
          placeholder="请选择"
          clearable
          filterable
          @change="commonFun"
        >
          <el-option
            v-for="item in quality3"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- </el-col>
        <el-col :span="6"> -->
      <div class="common-input-container">
        <span>业务类型:</span>
        <el-select
          v-model="params.businessType"
          class="common-screen-input"
          placeholder="请选择"
          clearable
          @change="commonFun"
          filterable
        >
          <el-option label="售前" value="售前"> </el-option>
          <el-option label="售中" value="售中"> </el-option>
          <el-option label="售后" value="售后"> </el-option>
        </el-select>
      </div>
      <!-- </el-col>
      </el-row> -->
      <!-- 第二行 -->
      <!-- <el-row>
        <el-col :span="6"> -->
      <div class="common-input-container">
        <span>品牌大类:</span>
        <el-select
          v-model="params.classificationBrandBigId"
          class="common-screen-input"
          placeholder="请选择"
          clearable
          filterable
          @change="(value) => dictFun(value, 'brand', 'brand2')"
        >
          <el-option
            v-for="item in brand1"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- </el-col>
        <el-col :span="6"> -->
      <div class="common-input-container">
        <span>品牌问题点:</span>
        <el-select
          v-model="params.classificationBrandPointId"
          class="common-screen-input"
          placeholder="请选择"
          clearable
          filterable
          @change="(value) => dictFun(value, 'brand', 'brand3')"
        >
          <el-option
            v-for="item in brand2"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- </el-col>
        <el-col :span="6"> -->
      <div class="common-input-container">
        <span>品牌问题描述:</span>
        <el-select
          v-model="params.classificationBrandDescId"
          class="common-screen-input"
          placeholder="请选择"
          clearable
          filterable
          @change="commonFun"
        >
          <el-option
            v-for="item in brand3"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- </el-col>
        <el-col :span="6"> -->
      <div class="common-input-container">
        <span>是否通过:</span>
        <el-select
          v-model="params.adopt"
          class="common-screen-input"
          placeholder="请选择"
          clearable
          @change="commonFun"
          filterable
        >
          <el-option label="是" value="通过"> </el-option>
          <el-option label="否" value="不通过"> </el-option>
        </el-select>
      </div>
      <!-- </el-col>
      </el-row> -->
      <!-- 第三行 -->
      <!-- <el-row>
        <el-col :span="6"> -->
      <div>
        <div class="common-input-container">
          <el-date-picker
            v-model="params.createTime"
            @change="() => commonFun('createTime')"
            type="daterange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <!-- </el-col>
        <el-col :span="6"> -->
        <el-radio-group v-model="tableRadio" @change="fastDataChange">
          <el-radio-button label="今天"></el-radio-button>
          <el-radio-button label="近7天"></el-radio-button>
          <el-radio-button label="近30天"></el-radio-button>
          <el-radio-button label="近90天"></el-radio-button>
        </el-radio-group>
        <!-- </el-col>
        <el-col :span="6"> -->
        <div class="common-input-container keyword">
          <span>质检人员:</span>
          <el-input
            v-model="params.keyword"
            class="common-screen-input"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </div>
        <!-- </el-col>
        <el-col :span="6"> -->
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="() => select()"
          >查 询</el-button
        >
        <el-button class="common-screen-btn clear" @click="() => clear()" plain
          >重 置</el-button
        >
      </div>
      <!-- </el-col>
      </el-row> -->
    </div>
    <!-- 导出按钮 -->
    <el-button
      class="common-screen-btn"
      type="primary"
      @click="() => download()"
      >导出质检记录表
    </el-button>
    <!-- 表格区域 -->
    <el-table class="common-table" :data="tableData" style="width: 100%">
      <el-table-column width="100px" prop="qualityName" label="质检人员">
      </el-table-column>
      <el-table-column width="180px" prop="qualityTime" label="质检时间">
      </el-table-column>
      <el-table-column width="100px" prop="serviceInspected" label="受检客服">
      </el-table-column>
      <el-table-column width="200px" prop="subaccountName" label="客服子账号">
      </el-table-column>
      <el-table-column width="200px" prop="customerAccount" label="顾客账号">
      </el-table-column>
      <el-table-column
        width="140px"
        prop="classificationQuality.bigList"
        label="问题大类"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        width="140px"
        prop="classificationQuality.pointList"
        label="问题点"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        width="180px"
        prop="classificationQuality.descList"
        label="问题描述"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column width="100px" prop="adopt" label="是否通过">
      </el-table-column>
      <el-table-column width="100px" prop="score" label="评分">
      </el-table-column>
      <el-table-column width="100px" prop="businessType" label="业务类型">
      </el-table-column>
      <el-table-column
        width="150px"
        prop="classificationBrand.bigList"
        label="品牌问题大类"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        width="150px"
        prop="classificationBrand.pointList"
        label="品牌问题点"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        width="180px"
        prop="classificationBrand.descList"
        label="品牌问题描述"
        show-overflow-tooltip
      >
      </el-table-column>
      <!-- <el-table-column width="150px" prop="address" label="同类问题数量">
      </el-table-column>
      <el-table-column width="100px" prop="address" label="占比">
      </el-table-column> -->
      <el-table-column width="130px" prop="remark" label="备注">
      </el-table-column>
      <!-- <el-table-column width="100px" prop="address" label="通知人">
      </el-table-column> -->
      <el-table-column width="100px" fixed="right" prop="address" label="操作">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <!-- 查看详情弹框 -->
    <el-dialog
    v-dialogDrag
      title="聊天记录"
      :visible.sync="chatHisDialogVisible"
      width="30%"
      :before-close="chatHisHandleClose"
      :append-to-body="true"
    >
      <!-- 弹框主体区域 -->
      <div class="chat-body">
        <ul
          v-for="(item, i) in chatList"
          :key="i"
          class="infinite-list"
          style="overflow: auto"
        >
          <li class="infinite-list-item3">
            <div class="checkBox">
              <div class="chat-title">{{ item.fromId }}</div>
              <div>{{ DateTransform(item.msgTime) }}</div>
            </div>

            <!-- 判断消息类型 -->
            <!-- <div class="third-msg"> -->
            <div
              :class="
                item.customerNick == item.fromId ? 'third-msg' : 'third-msg2'
              "
            >
              <span class="third-msg-content" v-if="item.msgType === 0"
                >{{ item.msgContent }}
              </span>
              <a
                :href="item.msgContent"
                target="_blank"
                v-else-if="item.msgType === 1"
                >点击跳转
              </a>
              <span class="third-msg-content" v-else-if="item.msgType === 2"
                >【{{ item.msgContent }}】--苹果手机表情+文字（不兼容）
              </span>
              <span class="third-msg-content" v-else-if="item.msgType === 3"
                >【表情图暂不支持查看】
              </span>
              <a
                :href="item.msgContent"
                target="_blank"
                v-else-if="item.msgType === 5"
                >淘宝链接--点击查看
              </a>
              <a
                target="_blank"
                :href="item.msgContent"
                v-else-if="item.msgType === 6"
              >
                【视频资源——点击查看】
              </a>
              <img
                :src="item.msgContent"
                v-if="item.msgType === 7"
                @click="showImg(item.msgContent)"
              />
              <span class="third-msg-content" v-else-if="item.msgType === 9"
                >【{{ item.msgContent }}】</span
              >
              <span class="third-msg-content" v-else-if="item.msgType === 10"
                >[{{ item.msgContent }}]
              </span>
            </div>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="chatHisHandleClose">取 消</el-button>
        <el-button type="primary" @click="chatHisHandleClose">确 定 </el-button>
      </span>
    </el-dialog>
    <!-- 图片dialog -->
    <el-dialog
    v-dialogDrag
      title="图片"
      :visible.sync="imgDialogVisible"
      width="30%"
      :before-close="imgHandleClose"
      :append-to-body="true"
    >
      <img class="imgdialog" :src="imgSrc" alt="" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgHandleClose">取 消</el-button>
        <el-button type="primary" @click="imgHandleClose">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import Breadcrumb from "../../components/common/breadcrumb.vue";
import {
  qualityHis,
  getListByParentId,
  qualityHisDetail
} from "../../service/service";
import { getShopDetail } from "../../service/common.js";
import { Config, DateTransform } from "../../utils/index.js";

export default {
  components: {
    Breadcrumb
  },
  props: {},
  data() {
    return {
      DateTransform,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "客服", isLink: false },
        { title: "客服质检", isLink: true, url: "/service/service-test" },
        { title: "质检记录", isLink: false }
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      shopDetail: {}, // 店铺详情
      quality1: [],
      quality2: [],
      quality3: [],
      brand1: [],
      brand2: [],
      brand3: [],
      //   搜索框数据
      params: {
        page: 1,
        pageSize: 10,
        shopId: this.$route.query.shopid,
        createTime: [Math.round(new Date()), Math.round(new Date())],
        classificationQualityBigId: "",
        classificationQualityPointId: "",
        classificationQualityDescId: "",
        classificationBrandBigId: "",
        classificationBrandPointId: "",
        classificationBrandDescId: "",
        adopt: "", // 是否通过
        businessType: ""
      },
      QualityBigIdArr: [], //问题类
      BrandBigIdArr: [],
      businessTypes: [],
      ////////////////////////////////////
      freeDate: [], //选择时间范围
      options: [],
      tableRadio: "今天",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      tableData: [],
      chatHisDialogVisible: false, //聊天记录弹框
      qualityCode: "", //质检历史详情查询参数,
      chatList: [],
      imgDialogVisible: false, //图片显示
      imgSrc: "" //图片地址
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    dictFun(value, type, key) {
      switch (key) {
        case "quality2":
          this.params.classificationQualityPointId = "";
          this.params.classificationQualityDescId = "";
          break;
        case "quality3":
          this.params.classificationQualityDescId = "";
          break;
        case "brand2":
          this.params.classificationBrandPointId = "";
          this.params.classificationBrandDescId = "";
          break;
        case "brand3":
          this.params.classificationBrandDescId = "";
          break;
      }
      this.getListByParentId(type, value, key);
      this.commonFun();
    },
    async getListByParentId(type, parentId, key) {
      if (parentId || parentId == 0) {
        let resData = (await getListByParentId({ type, parentId })).data;
        console.log(resData, "hahhahaha");
        this[key] = resData || [];
      }
    },
    async getShopDetail() {
      // 获取店铺详情
      let resData = (await getShopDetail({ shopId: this.$route.query.shopid }))
        .data;
      this.shopDetail = resData[0];
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.getQualityHis();
    },
    async getQualityHis() {
      try {
        this.loading = true;
        let params = { ...this.params };
        if (params.createTime && params.createTime.length) {
          params.startDate = DateTransform(params.createTime[0]);
          if (params.createTime[1]) {
            params.endDate = DateTransform(params.createTime[1]);
          }
          delete params.createTime;
        }
        const { data: res } = await qualityHis(params);
        this.pagination.total = res.total;
        this.pagination.pageSize = res.size;
        console.log(res, "列表****");
        this.tableData = res.list;
        this.loading = false;
      } catch (error) {
        this.$message.error("获取失败了");
      }
    },
    //   快速设置时间
    fastDataChange(time) {
      let d = [];
      if (time === "今天") {
        d = [Math.round(new Date()), Math.round(new Date())];
      }
      if (time === "近7天") {
        d = [
          Math.round(new Date() - 7 * 24 * 3600 * 1000),
          Math.round(new Date())
        ];
      }
      if (time === "近30天") {
        d = [
          Math.round(new Date() - 30 * 24 * 3600 * 1000),
          Math.round(new Date())
        ];
      }
      if (time === "近90天") {
        d = [
          Math.round(new Date() - 90 * 24 * 3600 * 1000),
          Math.round(new Date())
        ];
      }
      this.params.createTime = d;
      this.getQualityHis();
    },
    commonFun(type) {
      if (type == "createTime") {
        this.tableRadio = null;
      }
      this.params.page = 1;
      this.params.pageSize = 10;
      this.getQualityHis();
    },
    //查询按钮
    select() {
      this.getQualityHis();
    },
    clear() {
      this.params = {
        page: 1,
        pageSize: 10,
        shopId: this.$route.query.shopid,
        createTime: [Math.round(new Date()), Math.round(new Date())],
        classificationQualityBigId: "",
        classificationQualityPointId: "",
        classificationQualityDescId: "",
        classificationBrandBigId: "",
        classificationBrandPointId: "",
        classificationBrandDescId: "",
        adopt: "", // 是否通过
        businessType: ""
      };
      this.getQualityHis();
    },
    // 导出数据
    download() {
      let params = { ...this.params };
      delete params.page;
      delete params.pageSize;
      if (params.createTime && params.createTime.length) {
        params.startDate = DateTransform(params.createTime[0]);
        params.endDate = DateTransform(params.createTime[1]);
        delete params.createTime;
      }
      let url = Config.api.url + "/quality/qualityRecord/qualityHisExport";
      let paramArr = [];
      for (let item in params) {
        if (params[item]) {
          paramArr.push(item + "=" + params[item]);
        }
      }
      console.log(paramArr)
      url = url + "?" + paramArr.join("&");
      window.location.href = url;
    },
    // 查看详情点击事件
    handleClick(id) {
      console.log(id, "质检历史详情查询");
      this.qualityCode = id.qualityCode;
      console.log(this.qualityCode, "this.qualityCode");
      this.getChatHis();
      this.chatHisDialogVisible = true;
    },
    // 查看详情弹框-----关闭
    chatHisHandleClose() {
      this.chatHisDialogVisible = false;
    },
    async getChatHis() {
      const { data } = await qualityHisDetail({
        qualityCode: this.qualityCode
      });
      this.chatList = data;
    },
    // 图片弹出层关闭
    imgHandleClose() {
      this.imgDialogVisible = false;
    },
    // 单击事件
    showImg(val) {
      this.imgSrc = val;
      this.imgDialogVisible = true;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getListByParentId("quality", 0, "quality1");
    this.getListByParentId("brand", 0, "brand1");
    this.getShopDetail();
    this.getQualityHis();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.testRecord-container {
  text-align: left;
  .iconPeople {
    width: 80px;
    height: 80px;
    color: #fff;
    background: #87d068;
    border-radius: 50%;
    font-size: 70px;
    line-height: 80px;
    text-align: center;
    margin-right: 16px;
  }
  .detailTest-detail {
    display: flex;
    padding: 10px 10px 24px;
    align-items: center;
    color: #000000a6;
    .detail-img {
      width: 64px;
      height: 64px;
      margin-right: 20px;
      border-radius: 5px;
    }
    .detail-title {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: bold;
    }
    .detail-item {
      margin-right: 16px;
    }
  }
}
.common-screen-container {
  margin: 0;
}
.common-screen-btn {
  margin: 0 0 0 0;
}
.clear {
  margin-left: 8px;
}
.infinite-list-item3 {
  padding: 0 20px;
  margin-bottom: 5px;
  .checkBox {
    display: flex;
    justify-content: space-between;
    height: 21px;
    .chat-title {
      color: rgb(24, 144, 255) !important;
      margin-bottom: 3px;
    }
    .qualityFlag {
      border-radius: 3px;
      width: 40px;
      height: 20px;
      line-height: 20px;
      color: #333;
      background-color: #f4dda0;
      text-align: center;
      /* margin: 0 auto; */
      font-size: 10px;
    }
  }
  span {
    overflow: hidden;
    display: inline-block;
    max-width: 545px;
  }
  img {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
  }
}
/deep/.el-dialog__body {
  overflow: auto;
}
.chat-body {
  height: 300px;
}
.third-msg {
  background-color: #fafafa;
  border-radius: 5px;
  padding: 0 3px;
}
.third-msg2 {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 0 3px;
}
.imgdialog {
  display: block;
  width: 100%;
  height: auto;
}
.keyword{
  margin-left: 16px
}
</style>
